import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import parse from 'html-react-parser'
import { MappedImage } from '../Image'

const liveChatPopout = (event, link, orgId) => {
  const liveChatUrl = new URL(link)
  liveChatUrl.searchParams.append('orgId', orgId)
  event.preventDefault()
  window.open(
    liveChatUrl.toString(),
    '_blank',
    'height=600,width=500,location=0,menubar=0,toolbar=0'
  )
}

export const Footer = ({
  footerNavOne,
  footerNavTwo,
  footerText,
  footerLogo,
  copyrightText,
  payerFooter,
  orgId,
}) => {
  return (
    <footer
      className="footer py-4"
      style={{ backgroundColor: '#2067b7', color: '#fff' }}
    >
      <Container fluid className="py-4" style={{ maxWidth: '1200px' }}>
        <Row className="footer-inner mx-auto">
          <Col sm={12} md={3} className="mb-3 footer-col-left mt-0">
            <div className="h-100 w-100 d-flex flex-column justify-content-start">
              {footerLogo && (
                <div className="logo-wrapper mb-4">
                  <MappedImage
                    style={{ maxHeight: '75px' }}
                    image={footerLogo}
                    alt={`${footerLogo?.title}`}
                  />
                </div>
              )}
              <div className="footer-nav">
                <ul className="nav flex-column footer-nav-list">
                  {footerNavOne &&
                    footerNavOne.map(item => {
                      return (
                        <li key={item.navigationTitle} className="nav-item">
                          <a
                            href={item.navigationLink}
                            className="nav-link text-white footer-top-nav"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item.socialIcon ? (
                              <MappedImage image={item.socialIcon} />
                            ) : (
                              item.navigationTitle
                            )}
                          </a>
                        </li>
                      )
                    })}
                </ul>
              </div>
            </div>
          </Col>
          <Col sm={12} md={9} className="footer-col-right">
            {payerFooter && parse(payerFooter.payerFooter)}
            {footerText && parse(footerText.footerText)}
            {copyrightText && parse(copyrightText.copyrightText)}
          </Col>
        </Row>
        <Row></Row>
        <Row className="footer-bottom-nav my-4">
          <div className="footer-subnav">
            <ul className="nav justify-content-center">
              {footerNavTwo &&
                footerNavTwo.map(item => {
                  return (
                    <li
                      key={item.navigationTitle}
                      className="nav-item subnav-item"
                    >
                      {item.navigationTitle === 'Live chat' ? (
                        <button
                          onClick={event =>
                            liveChatPopout(
                              event,
                              item.navigationLink,
                              orgId || 'GOSOLERA'
                            )
                          }
                          className="nav-link footer-button text-white"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {item.navigationTitle}
                        </button>
                      ) : (
                        <a
                          href={item.navigationLink}
                          className="nav-link text-white"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {item.navigationTitle}
                        </a>
                      )}
                    </li>
                  )
                })}
            </ul>
          </div>
        </Row>
      </Container>
    </footer>
  )
}

export const mapFooterProps = props => {
  const {
    phoneNumber,
    emailAddress,
    contactWrapperOne,
    contactWrapperTwo,
    footerNavOne,
    footerNavTwo,
    footerText,
    footerLogo,
    socialItems,
    copyrightText,
    payerFooter,
    orgId,
  } = props
  return {
    phoneNumber,
    emailAddress,
    contactWrapperOne,
    contactWrapperTwo,
    footerNavOne,
    footerNavTwo,
    footerText,
    socialItems,
    copyrightText,
    payerFooter,
    footerLogo,
    orgId,
  }
}
